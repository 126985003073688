@import 'common';

.Topics {
  @extend %Screen;

  .content-container {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0 auto;
  }

  .content-container-wrapper {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    width: rem(670); // hack for IE 11, can be any absolute value
  }

  .topic-text-container {
    display: flex;
    flex-flow: column nowrap;

    @include media-mobile {
      flex: 1 0 auto;

      // DO NOT REMOVE THIS: hack for ios safari
      // always enable scrolling even when height couldn't be calculated
      min-height: 100%;
      margin-bottom: 1px;
    }
  }

  @include media-desktop() {
    .screen-container {
      display: flex;
      flex-direction: row;
      //warning! check to use only flex-basis: auto not flex-basis: 0 for IE11 support
      flex: 1 1 auto;
      padding: 0 rem(20);
      transform: none;

      .ContentList {
        flex-grow: 1;
        flex-shrink: 0;
      }

      .ContentList.TopPicksList {
        padding: 0;
      }
    }

    &.one .screen-container {
      flex-direction: column;
    }
  }

  @include media-mobile("landscape") {
    &.one.video {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 4;

      & .screen-head {
        display: none;
      }

      & .video-player-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .sticky-topics-bar {
    min-height: rem($bread-crumb-height);
    margin: 0 0 rem(20);

    .topics-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      font-size: rem(14);
      line-height: rem(34);
      padding: rem(15) 0;
      margin: 0 rem(20);
      box-shadow: inset 0 -1px #E2E2E2;
      color: #b2b2b2;
      height: rem($bread-crumb-height);
    }

    .sticky {
      width: 100%;
      left: 0;
      top: rem($nav-bar-height);
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

      .topics-bar {
        background-color: white;
        box-shadow: none;
        width: rem($content-width);

        @include media-tablet {
          width: 100%;
          max-width: rem($content-width);
        }

        padding: 0 rem(20) 0 rem(20);
        margin: 0 auto;
      }
    }
  }

  .sticky-related-contents-bar {
    width: rem(370);

    .sticky {
      top: rem(140);
    }
  }

  .topics {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;

    &-subtitle {
      font-size: rem(18);
      line-height: rem(28);

      color: #000;
      font-weight: bold;
      position: relative;
      padding: rem(17) rem(20) rem(17) rem(20);

      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      text-align: left;

      box-shadow: inset 0 -1px #E2E2E2;
      background-color: #f7f7f7;

      & .icon-bookmark {
        width: rem(26);

        .stroke {
          stroke: black
        }
      }

      @include media-desktop {
        font-size: rem(14);
        line-height: rem(34);
        margin: 0 0 rem(20);
        padding: rem(15) rem(40) rem(15) rem(0);
        background: none;
        text-transform: uppercase;
        color: #000;
      }
    }

    &-breadcrumb {
      font-size: rem(14);
      line-height: rem(34);
      font-weight: 500;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      text-transform: uppercase;


      li {
        display: flex;
        align-items: center;
        white-space: nowrap;

        a {
          text-decoration: none;
          color: inherit;
        }

        span:last-child {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon {
          display: block;
          margin: 0 rem(22);
          flex-shrink: 0;

          @include media-tablet {
            margin: 0 rem(16);
          }

          width: rem(4);
          height:rem(7);
          transform: rotate(180deg);

          .stroke {
            stroke: #b2b2b2;
          }
        }

        &:last-child {
          .icon {
            display: none;
          }

          color: #000;

          flex: 1;
        }
      }

      &.inactive li:last-child {
        color: inherit;
      }
    }
  }

  .video-container {
    display: flex;
    flex-flow: column nowrap;

    @include media-desktop {
      max-width: rem(980);
    }

    @include media-mobile {
      padding: rem(20);
    }

    .video-player-container,
    .video-player-native {
      @include media-desktop {
        margin-bottom: rem(25);
        min-height: rem(370);

        @include media-tablet {
          min-height: 0;
        }
      }

      @include media-mobile {
        padding-bottom: rem(15);
      }

      .vjs-poster {
        background-repeat: repeat;
      }
    }

    .video-markers-container {
      background-color: #f7f7f7;

      @include media-desktop {
        border: solid 1px #e0e0e0;
      }

      .video-markers {
        flex: 1;
        padding: rem(10) rem(30);

        @include media-mobile {
          padding: rem(15) rem(25);
        }

        button {
          color: #495360;
          font-size: rem(14);
          padding: rem(12) 0;
          display: block;
          cursor: pointer;
          text-align: left;

          @include media-mobile {
            font-size: rem(16);
            padding: rem(10) 0
          }

          @include media-desktop {
            &:hover {
              color: #000;
            }
          }
        }
      }
    }
  }

  .video-player-native {
    position: relative;
    text-align: center;
    display: block;
    flex-shrink: 0;

    img {
      width: 100%;
    }

    div.play {
      position: absolute;
      transform: translate(0, 0);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {

        font-size: 2em;
        line-height: 1.4em;
        height: 1.5em;

        width: 3em;
        display: block;
        position: absolute;
        cursor: pointer;
        opacity: 1;
        color: white;
        border: .06666em solid #fff;
        font-weight: 400;
        background-color: rgba(43, 51, 63, .7);
        border-radius: .3em;
        transition: all .4s;

        font-family: VideoJS;

        &:before {
          content: "\F101";
        }
      }
    }
  }

  .topic-back {
    position: relative;
    font-size: rem(14);
    color: #000;
    text-transform: uppercase;
    line-height: rem(15);
    font-weight: normal;
    flex-shrink: 0;

    a {
      text-decoration: none;
      color: #000;
    }

    .icon {
      position: absolute;
      width: rem(5);
      height: rem(8);
      left: rem(-22);
      top: rem(3);
      //transform: rotate(180deg);

      .stroke {
        stroke: #000;
        stroke-width: 2;
      }
    }
  }

  .topic-title {
    font-size: rem(28);
    color: #000;
    margin-bottom: rem(20);
    font-weight: bold;

    & .icon-bookmark {
      width: rem(24);
      height: rem(24);
      top: rem(3);
      margin-right: rem(15);

      .stroke {
        stroke: black
      }
    }
  }

  .topic-subtitle {
    font-weight: 600;
    color: #000;

    @include media-desktop {
      font-size: rem(24);
      margin: rem(20) 0;
    }

    @include media-mobile {
      font-size: rem(22);
      margin: rem(10) rem(20);
    }
  }

  .topic-content {
    @extend .screen-text;

    flex: 1 0 auto;

    @include media-desktop() {
      flex: none;
    }

    @include media-mobile() {
      margin: rem(20);
    }

    p,
    li,
    strong {
      color: #495360;
      font-family: 'Open Sans', sans-serif;
      font-size: rem(16);
      line-height: rem(24);
      font-weight: 400;
    }

    strong {
      font-weight: 600;
    }

    h1.title {
      display: none;
    }

    h2.title {
      color: black;
      font-weight: bold;
      font-size: rem(22);
    }

    .image {
      vertical-align: middle;
      max-height: none;
      max-width: 100%;
      margin: 0 0 1em 0;

      @include media-mobile {
        width: auto;
      }
    }

    .fig {

      .image {
        max-height: none;
        max-width: 100%;
      }

      margin: 0 0 rem(20) 0;
    }

    .ul,
    .ol {
      padding-left: rem(40);

      .li {
        margin: rem(5) 0;
      }
    }

    .ul>.li {
      list-style-type: disc;
    }

    .ol>.li {
      list-style-type: decimal;
    }

    .parentlink {
      display: none;
    }

    .note_warning,
    .note_danger,
    .note_caution,
    .note_note {

      margin: rem(20) 0;
      padding: 0 rem(15) rem(15) rem(15);

      line-height: rem(24);
      color: #495360;

      .note__title {
        display: block;
        padding: rem(5) rem(15);
        text-transform: uppercase;
        font-weight: bold;
        margin: 0 rem(-15) rem(10) rem(-15);
      }

      .p {
        padding: 0;
        margin-bottom: 0;
      }

      ol,
      ul {
        padding: 0 rem(15) rem(5) rem(30);

        .p {
          padding: 0 0;
        }
      }
    }

    %warning_title {
      padding: rem(10) rem(10) rem(10) rem(48);
      background-image: url('../svg/warning.svg');
      background-size: rem(22) rem(22);
      background-repeat: no-repeat;
      background-position: rem(15) 50%;
    }

    .note_warning {
      background-color: #f9e2d0;

      .note__title {
        @extend %warning_title;
        color: white;
        background-color: #e3711c;
      }
    }

    .note_danger {
      background-color: #ffdbdc;

      .note__title {
        @extend %warning_title;
        color: white;
        background-color: #c70100;
      }
    }

    .note_caution {
      background-color: #f8e6ad;

      .note__title {
        @extend %warning_title;
        color: white;
        background-color: #f7c00b
      }
    }

    .note_note {
      background-color: #f6f6f6;

      .note__title {
        padding: rem(10) rem(15);
        color: #495360;
        background-color: #efefef;
      }
    }

    .table {
      margin-bottom: rem(20);

      @include media-mobile {
        display: block;
        overflow-x: auto;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          height: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .5);
          box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
      }

      th,
      td {
        border: 1px solid black;
        padding: rem(5);
        vertical-align: top;
      }

      th {
        background-color: silver;
        color: black;
      }
    }

    // Small redundant icon for external URLs in the topic should not be rendered
    .image[data-image-id="GUID-DNC-ABARTH-GENERIC-URL-low.png"] {
      display: none
    }

    pre.codeblock code {
      // IE11 add extra line break before the content inside the <code> block
      display: block;
    }

    pre.codeblock code a {
      margin: rem(5) 0;
      padding: rem(5) rem(20);

      width: rem(300);

      @include media-mobile {
        width: 100%;
      }

      color: white;
      font-weight: bold;
      display: block;
      text-align: center;
      text-decoration: none;

      &:active {
        opacity: 0.7;
      }

      &[target="_blank"] {
        text-align: center;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        display: block;

        padding: rem(5) rem(0) rem(5) rem(45);
        margin: 0;
        background: url('../images/weblink-icon.png') no-repeat 20% center;
        background-size: contain;

        @include media-mobile {
          background-position: calc(50% - 75px) center;
          margin: 0 auto;
        }

        cursor: pointer;
      }
    }
  }

  .ToastContainer {
    @include media-desktop {
      display: flex;
      flex-direction: column;
    }

    .toast-message-container {
      padding: rem(10) rem(20);
      font-size: rem(16);

      .toast-message {
        display: flex;
        flex-direction: row;
        align-items: center;

        .text {
          font-weight: bold;
          font-size: rem(14);
          margin-left: rem(20);
          line-height: normal;
        }

        .icon-success {
          height: rem(28);
          width: rem(28);
          min-width: rem(28)
        }
      }

      @include media-desktop {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: rem(56);
        width: rem(590);
        margin: 0 auto;
        font-weight: bold;
        border-radius: rem(4);
      }
    }
  }
}


.modal-container {
  color: #000;
  background-color: #fff;
  min-width: rem(300);
  max-width: 90%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: rem(10);
  padding: rem(10);
  border-radius: rem(6);
  .modal-header {
    div {
      text-align: center;
      font-weight: 600;
      font-size: rem(18);
    }
  }

  .modal-body {
    .option-container {
      color: #000;
      text-decoration: none;
      cursor: pointer;
      background-color: #f7f7f7;
      padding: rem(10);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem(10);

      .option-title {
        font-size: rem(15);
      }

      .icon-container {
        max-width: rem(23);
      }
    }
  }

  .modal-footer {
    font-size: rem(15);
    cursor: pointer;
    text-align: center;
    margin-bottom: rem(10);
  }
}