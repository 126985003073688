@import "common";

.PdfViewer {
  .download {
    display: flex;
    align-items: center;
    padding-left: rem(20);

    @include media-mobile {
      padding-top: rem(20);
    }

    .icon {
      width: rem(24);
      margin-right: rem(5);
      cursor: pointer;
    }

    a {
      text-decoration: none;
      color: #22577D;
    }
  }

  .react-pdf__Page {
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    margin-top: 20px;
    margin-bottom: 25px;
  }

  canvas.react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }

  .react-pdf__message--loading {
    margin-top: rem(25);
  }

  .pdf-renderer-conatiner {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .desktop-conatiner {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: rem(10);
      gap: rem(40);

      .page-control-conatiner {
        font-size: rem(15);
        display: flex;
        align-items: center;

        .title {
          font-size: rem(14);
          color: #000;
        }
        .rpv-page-navigation__current-page-input {
          width: rem(45);
        }
      }

      .zoom-control-conatiner {
        display: flex;
        align-items: center;
        .rpv-zoom__popover-target-scale{
          font-size: rem(15);
        }
      }
    }

    .mobile-conatiner {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: rem(25);

      .page-control-conatiner {
        display: flex;
        align-items: center;
      }

      .zoom-control-conatiner {
        display: flex;
        align-items: center;
      }
    }

  }
  .download_only {
    width: rem(36);
    height: rem(36);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      max-width: rem(23);
    }
  }

  .download_only:hover {
    background-color: #e9ecef;
    border-radius: 4px; 
  }
}
